//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import SignHistoryCard from './SignHistoryCard.vue';
import SignTypeLabel from './components/SignTypeLabel.vue';

export default {
  name: 'SignerHistory',
  props: ['signerHistory'],
  components: {SignHistoryCard, SignTypeLabel},
  data(){
    return {
      worker: null,
      signType: null,
      eventTypeTranslated: null,
      eventType: null,
      date: null,
      collapseVisible: false,
      format: "YYY-MM-DD HH:MM:SS"
    }
  },
  created() {
    const { worker, signType, eventType, eventTypeTranslated, date } = this.currentSign;
    this.worker = worker;
    this.signType = signType;
    this.eventType = eventType;
    this.eventTypeTranslated = eventTypeTranslated;
    this.date = (date && date.date) ? date.date.split('.')[0] : '';
  },
  computed: {
    currentSign() {
      let currentSign = null;
      Object.values(this.signerHistory).forEach(type => type.forEach(sign => {
        if (currentSign) {
          let next = moment(sign.date.date, this.format);
          let current = moment(currentSign.date.date, this.format);
          if (next.isAfter(current, 'second'))
            currentSign = sign;
        }
        else {
          currentSign = sign;
        }
      }));
      return currentSign;
    },
    fio() {
      return `${this.signerHistory.surName} ${this.signerHistory.firstName} ${this.signerHistory.middleName}`;
    },
    localeDate() {
      let localeDate = this.date;
      if (localeDate) {
        const signDate = new Date(localeDate);
        if (signDate instanceof Date) {
          localeDate = signDate.toLocaleString();
        }
      }
      return localeDate;
    }
  }
}
